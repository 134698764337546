<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('smplForm')"
        @cancel="cancel"
    ></add-header>
    <el-form ref="smplForm" :rules="rules" :model="smplForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="单据编号" prop="smpl_no">
            <el-input v-model="smplForm.smpl_no" @input ="smplForm.smpl_no = helper.keepEngNum2(smplForm.smpl_no)" maxlength="10"  show-word-limit placeholder="请填写单据编号">
            </el-input>
          </el-form-item>
          <el-form-item label="产品货号" prop="prod_no">
            <el-input v-model="smplForm.prod_no" maxlength="10" show-word-limit placeholder="请填写产品货号">
              <el-link slot="append" type="primary" @click="notice = true;">导入</el-link>
            </el-input>
          </el-form-item>
          <el-form-item label="委托单号" prop="smpl_ask_no">
            <el-input v-model="smplForm.smpl_ask_no" maxlength="30" show-word-limit placeholder="请填写委托单号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样数量" prop="smpl_num">
            <el-input v-model="smplForm.smpl_num" maxlength="10" @input ="smplForm.smpl_num = helper.keepTNum(smplForm.smpl_num)" show-word-limit placeholder="请填写打样数量"></el-input>
          </el-form-item>
          <el-form-item label="产品名称" prop="prod_name">
            <el-input v-model="smplForm.prod_name" maxlength="30" show-word-limit placeholder="请填写产品名称"></el-input>
          </el-form-item>
          <el-form-item label="产品规格" prop="prod_spec">
            <el-input v-model="smplForm.prod_spec" maxlength="50" show-word-limit placeholder="请填写产品规格"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交样时间" prop="smpl_deadline">
            <el-date-picker
              v-model="smplForm.smpl_deadline"
              type="date"
              placeholder="选择录入时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="产品类型" prop="prod_type">
            <el-select v-model="smplForm.prod_type" placeholder="请选择产品类型" clearable>
              <el-option
                  v-for="item in smplTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="smpl_remark">
            <el-input type="textarea" autosize v-model="smplForm.smpl_remark" maxlength="255" show-word-limit placeholder="请填写备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog
        :title="title"
        :visible.sync="notice"
        width="70%">
        <SmplEditIn @confirmIn="confirmIn"></SmplEditIn>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {smplAPI} from "@api/modules/smpl";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import SmplEditIn from "@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditIn";
import {optnAPI} from "@api/modules/optn";

export default {
  name: "smplAddMain",
  components: {
    addHeader,
    inputUser,
    SmplEditIn
  },
  data() {
    return {
      rules:{
        smpl_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        smpl_num:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        smpl_deadline:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        prod_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        prod_name:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      smplForm:{
        prod_no: '',
        prod_name: '',
        smpl_no: '',
        prod_type: '',
        smpl_num: '',
        smpl_deadline: '',
        prod_spec:'',
        status:0,
        key:0,
      },
      smplTypeGroupOpt:[],
      smplEditIn:{},
      notice:false,
      title:'',
    }
  },
  watch: {
  },
  created() {
    this.getSmplType()
  },
  methods:{
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.smplTypeGroupOpt = res.data.data
        }
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
    },
    // 保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      if (this.smplForm.smpl_no === ''){
        let date = (new Date()).getTime() / 1000;
        let num = parseInt(Math.random()*99-10);
        this.smplForm.smpl_no = date+num;
      }
      let smplForm1 = {}
      smplForm1 = Object.assign(smplForm1,this.smplForm,staffForm)
      smplForm1.smpl_deadline = (new Date(this.smplForm.smpl_deadline)).getTime() / 1000;
      post(smplAPI.addSmpl,smplForm1)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          const permId = this.$route.query.perm_id
          this.jump(`/smpl_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
          this.resetForm('smplForm')
        }else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'单据编号重复'
          })
          smplForm1.smpl_deadline = (new Date(this.smplForm.smpl_deadline)).getTime() * 1000;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          smplForm1.smpl_deadline = (new Date(this.smplForm.smpl_deadline)).getTime() * 1000;
        }
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('smplForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
    },
    // 导入选择确定
    confirmIn(val){
      this.smplEditIn = val;
      this.notice = false;
      this.assignment()
    },
    // 导入赋值
    assignment(){
      this.smplForm.prod_name = this.smplEditIn.prod_name;
      this.smplForm.prod_no = this.smplEditIn.prod_no;
      this.smplForm.prod_spec = this.smplEditIn.prod_spec;
      this.smplForm.prod_type = this.smplEditIn.prod_type;
      this.smplForm.prod_id = this.smplEditIn.prod_id;
    },
    // 成功上传
    handleAvatarSuccess(res){
      this.smplForm.imge_id = res.data.imge_id
      this.smplForm.imge_url = this.helper.picUrl(res.data.imge_url,'m')
    },
    // 上传头像前
    beforeAvatarUpload(){},
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>